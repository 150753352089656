



















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Editor from '@/components/Editor/index.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { DatePicker, Autocomplete, Checkbox } from 'element-ui'
Vue.use(DatePicker)
Vue.use(Autocomplete)
Vue.use(Checkbox)

@Component({
  components: {
    Editor
  }
})
export default class extends Vue {
  info = {} as any
  // 信息发布类型
  newsTypeDicts: any = []

  async beforeCreate() {
    // 获取相关字典
    // 信息发布类型
    this.newsTypeDicts = await getDicts('NOTICE_TYPE')
  }
  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  mounted() {
    if (this.$route.query.id) {
      this.$api.property
        .getNoticeInfo(this.$route.query.id)
        .then((res: any) => {
          if (res.data.code === '200' && res.data.success) {
            this.info = res.data.data
          }
        })
      // this.info = JSON.parse(this.$route.query.info as any)
    }
  }

  goback() {
    this.$router.push('/property/news-history')
  }
}
